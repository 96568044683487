.@{class-prefix}-savings {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  overflow-x: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

  &-card {
    min-width: 220px;
    max-width: 100%;
    align-self: stretch;
    border-radius: 4px;
    padding: 16px 24px;
    cursor: pointer;
  }
}
