.ant-carousel .slick-prev {
  left: 0;
}
.ant-carousel .slick-next {
  right: 0;
  &::after {
    content: '→';
  }
  &::before {
    content: '';
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  width: fit-content;
  z-index: 1;

  &:hover {
    color: #0063f7;
  }
  &:focus {
    color: #0010f7;
  }
  &.slick-disabled {
    color: #9ca3af;
  }
}
