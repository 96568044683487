.@{class-prefix}-balances {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  overflow-x: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

  &-card {
    min-width: 220px;
    max-width: 100%;
    border-radius: 4px;
    padding: 16px 24px;
    border: 2px solid transparent;
    cursor: pointer;

    &:hover {
      border: 2px solid @primary-1;
    }
  }
}
